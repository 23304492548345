
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DialogLayout from '@/components/DialogLayout.vue';
import { QuickDialog } from '@/store/models/DialogModel';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { InvoiceService } from '@/services/invoice-service';
@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  $refs!: {
    invoiceDialog: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Prop()
  orderlines!: any;
  /* Watchers */
  @Watch('dialog')
  onDialogChange() {}

  /* Data */
  dialog: boolean = false;
  invoiceId: string = '';
  paymentProfileId: string = '';
  dialogLayoutData!: QuickDialog;

  isTextInputValid: boolean = false;
  /* Methods */
  async openDialog() {
    if (this.orderlines) {
      this.dialogLayoutData = ConversionHelper.convertOrderlineToDialogLayout(
        this.orderlines.Orderlines[0]
      );
      const response = await InvoiceService.GetInvoiceByPO(this.orderlines.GroupByValue);
      this.dialog = true;
    }
  }
  onButtonClick() {}
  closeDialog() {
    this.dialog = false;
  }

  /* Loaders */
  /* Mounted */
  mounted() {}
  /* Created */
}
